<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :finish-button-text="lang('t_send')"
      :next-button-text="lang('t_next')"
      :back-button-text="lang('t_previous')"
      class="steps-transparent mb-3"
      @on-complete="special_dialog"
    >
      <!-- account detail tab -->
      <tab-content :title="lang('t_operator')" icon="bi bi-broadcast">
        <b-row class="mx-0 mb-1">
          <b-form-radio
            @input="filter_values.selectedOperator = ''"
            value="sms"
            v-model="filter_values.type"
            >SMS
          </b-form-radio>
          <b-form-radio
            @input="filter_values.selectedOperator = ''"
            class="ml-3"
            value="whatsapp"
            v-model="filter_values.type"
            >Whatsapp
          </b-form-radio>
        </b-row>
        <b-form-group>
          <v-select
            v-if="filter_values.type == 'sms'"
            v-model="filter_values.selectedOperator"
            :options="operators"
            :placeholder="lang('t_operator')"
            :reduce="(val) => val"
            label="display_name"
            :clearable="false"
          >
          </v-select>
          <v-select
            v-else
            v-model="filter_values.selectedOperator"
            :options="wp_providers.filter((e) => e.status)"
            :placeholder="lang('t_whatsapp')"
            :reduce="(val) => val"
            label="display_name"
            :clearable="false"
          >
          </v-select>
        </b-form-group>
      </tab-content>

      <!-- address -->
      <tab-content :title="lang('t_project')" icon="bi bi-rocket">
        <b-form-group>
          <v-select
            v-model="filter_values.selectedProject"
            :options="projects"
            label="display_name"
            :reduce="(val) => val"
            :placeholder="lang('t_project')"
            :clearable="false"
          >
          </v-select>
        </b-form-group>
      </tab-content>

      <!-- personal details -->
      <tab-content :title="lang('t_smsTemplate')" icon="bi bi-file-richtext">
        <b-form-group>
          <v-select
            v-if="filter_values.selectedProject.internal_name != ''"
            :options="
              templates.filter((e) => {
                return e.project == filter_values.selectedProject.internal_name;
              })
            "
            v-model="filter_values.selectedMessageTemplate"
            :reduce="(val) => val"
            :placeholder="lang('t_smsPattern')"
            label="display_name"
            :clearable="false"
          >
          </v-select>
        </b-form-group>
        <b-form-group>
          <v-select
            v-model="filter_values.selectedFilters"
            :options="
              filters.filter((e) => {
                return e.project == filter_values.selectedProject.internal_name;
              })
            "
            label="display_name"
            :placeholder="lang('t_filter')"
            :clearable="false"
          >
          </v-select>
        </b-form-group>
      </tab-content>

      <!-- social link -->
      <tab-content :title="lang('t_preview')" icon="bi bi-phone">
        <b-row>
          <b-col>
            <center>
              <div style="position: relative">
                <b-img style="height: 50vh" src="phone.png" alt="phone" />
                <div
                  class="d-flex align-items-center"
                  style="
                    position: absolute;
                    top: 25.9%;
                    left: 25%;
                    z-index: 9999;
                    color: black;
                    max-width: 16vw;
                  "
                >
                  <b-img
                    class="mr-50"
                    :src="menu_logo_sq"
                    fluid
                    width="16"
                  ></b-img>
                  <span class="text-dark font-medium-2 font-weight-bolder">
                    {{
                      new Date().toLocaleTimeString("tr-TR", {
                        hour: "numeric",
                        minute: "2-digit",
                      })
                    }}
                  </span>
                </div>

                <div
                  class="d-flex align-items-center justify-content-center"
                  style="
                    position: absolute;
                    top: 60%;
                    left: 45%;
                    transform: translate(-50%, -50%);
                    z-index: 9999;
                    color: white;
                    max-width: 34vw;
                  "
                >
                  <span
                    class="d-block msg_from bg-light-secondary font-small-3 text-left"
                    style="max-height: 22vh; overflow-y: auto"
                  >
                    {{ filter_values.selectedMessageTemplate.message }}
                    <!-- <small class="time text-secondary font-small-1">
                      {{
                        new Date().toLocaleTimeString("tr-TR", {
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      }}
                    </small> -->
                  </span>
                </div>
              </div>
            </center>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
    <b-card-text v-if="false">
      <b-row>
        <b-col cols="12">
          <!-- Operatör -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedOperator"
              :options="operators"
              :placeholder="lang('t_operator')"
              :reduce="(val) => val"
              label="display_name"
              :clearable="false"
            >
            </v-select>
          </b-form-group>
          <!-- Proje -->
          <b-form-group>
            <v-select
              v-model="filter_values.selectedProject"
              :options="projects"
              :reduce="(val) => val"
              label="display_name"
              :placeholder="lang('t_project')"
              :clearable="false"
            >
            </v-select>
          </b-form-group>
          <!-- Mesaj ve Filtre -->
          <b-row>
            <b-col>
              <b-form-group>
                <v-select
                  v-if="filter_values.selectedProject.internal_name != ''"
                  :options="
                    templates.filter((e) => {
                      return (
                        e.project == filter_values.selectedProject.internal_name
                      );
                    })
                  "
                  v-model="filter_values.selectedMessageTemplate"
                  :reduce="(val) => val"
                  :placeholder="lang('t_smsPattern')"
                  label="display_name"
                  :clearable="false"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  v-model="filter_values.selectedFilters"
                  :options="
                    filters.filter((e) => {
                      return (
                        e.project == filter_values.selectedProject.internal_name
                      );
                    })
                  "
                  :clearable="false"
                  label="display_name"
                  :placeholder="lang('t_filter')"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-textarea
                v-if="show_textarea"
                v-model="filter_values.selectedMessageTemplate.message"
                class="fontFeatures mb-1"
                :label="lang('t_exampleText')"
                rows="12"
                readonly
                disabled
              >
              </b-form-textarea>
            </b-col>
          </b-row>
          <!-- Butonlar -->
          <b-button
            @click="special_dialog()"
            :disabled="preview_click == false"
            variant="success"
            style="float: right"
          >
            {{ lang("t_send") }}
          </b-button>
          <b-button
            variant="gradient-primary"
            style="float: right; margin-right: 10px"
            class="ml-1"
            :disabled="filter_values.selectedMessageTemplate == ''"
            @click="GetSmsData"
          >
            {{ lang("t_preview") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardText, BLink, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BButton, BFormTextarea, BFormRadio,
  BFormRadioGroup,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { codeIconInfo } from './code'
import "vue-form-wizard/dist/vue-form-wizard.min.css";
export default {
  components: {
    FormWizard,
    TabContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,
    BImg,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      menu_logo_sq: globalThis.env.MENU_LOGO_SQ,
      filter_values: {
        type: 'sms',
        selectedOperator: {},
        selectedProject: [],
        selectedMessageTemplate: [],
        selectedFilters: [],
      },
      is_progress: false,
      operators: [],
      wp_providers: [],
      filters: [],
      projects: [],
      campaigns: [],
      templates: [],
      filtered_data: [],
      smsdata: [],
      smsexceldata: [],
      columns: [],
      show_textarea: false,
      preview_message: '',
      preview_click: false,
    }
  },
  mounted: function () {
    this.get_smsfilters();
    this.get_smstemplate();
    this.get_projects();
    this.get_smssettings();
    this.get_wp_providers();
  },
  methods: {
    get_wp_providers: async function () {
      var response = (
        await this.$http_in.get(`text-channel-wp/v1/WhatsappProvider`)
      ).data;
      console.log("WhatsappProvider", response);
      this.wp_providers = response;
    },
    mongoize_campaign_filters(filters) {
      if (![null, undefined].includes(filters)) {
        const operands = {
          "<": "$lt",
          "<=": "$lte",
          ">": "$gt",
          ">=": "$gte",
          "=": "$eq"
        };

        var _mongoized_filters = Object.create(null);
        for (var f of filters) {
          f.value = f.value.replaceAll('{{Today}}', new Date().toISOString().split('T')[0]);
          // attempts veya total_attempts için değeri her zaman sayısal türe çevir
          if (['attempts', 'total_attempts'].includes(f.column)) {
            f.value = Number(f.value);
          }
          //  else {
          //   if (!isNaN(f.value)) {
          //     f.value = Number(f.value);
          //   }
          // }

          if (["<", "<=", ">", ">="].includes(f.operand)) {
            if (f.column in _mongoized_filters) {
              _mongoized_filters[f.column] = { ..._mongoized_filters[f.column], [operands[f.operand]]: f.value };
            } else {
              _mongoized_filters[f.column] = { [operands[f.operand]]: f.value };
            }
          }

          if (["="].includes(f.operand)) {
            if (f.column in _mongoized_filters) {
              if (!_mongoized_filters[f.column]["$in"]) {
                _mongoized_filters[f.column]["$in"] = [];
              }
              _mongoized_filters[f.column]["$in"].push(f.value);
            } else {
              _mongoized_filters[f.column] = { "$in": [f.value] };
            }
          }
        }
        return _mongoized_filters;
      }
      return null;
    },
    async special_dialog() {
      await this.GetSmsData();

      if (this.filter_values.selectedFilters.length <= 0) {
        this.$swal({
          icon: 'error',
          title: this.lang('t_description'),
          text: this.lang('t_noFilterHasBeenSelected'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      } else {
        this.$swal({
          title: this.lang('t_areYouSure'),
          text: this.filtered_data.length + this.lang('t_smscount'),
          //  {{ `${special_info.length} adet mesaj gönderilecektir. Onaylıyor musunuz?` }}
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.lang('t_yes'),
          cancelButtonText: this.lang('t_cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',

          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.send_sms();
          }
        });
      }
    },
    send_sms: async function () {

      let messages = '';
      let phones = '';

      for (const row of this.filtered_data) {
        let _message = '';
        _message = this.preview_message;

        for (const key of Object.keys(row)) {
          _message = _message.replace('{{' + key + '}}', row[key]);
        }

        _message = _message.replace('{{vName}}', globalThis.user.name);
        _message = _message.replace('{{vSurname}}', globalThis.user.surname);
        _message = _message.replace('{{vDescription}}', globalThis.user.description);


        // if (row['phones'].Telefon.substr(-10)) {
        console.log("row", row);
        phones = this.filter_values.selectedOperator.prefix ? this.filter_values.selectedOperator.prefix + row['phones'].Telefon.substr(-10) : '90' + row['phones'].Telefon.substr(-10);
        messages = _message.trim();
        // }

        this.smsdata = {
          messages: messages.trim(),
          phones: phones,
        };

        console.log('smsdata', this.smsdata);
        var response = (
          await this.$http_in.post(
            `spv/v1/SendSms`,
            {
              data: this.smsdata,
              sending_info: {
                ...this.filter_values,
                sms_settings: this.filter_values.selectedOperator,
                collection: `${this.filter_values.selectedProject.internal_name}_customers`,
                template: this.filter_values.selectedMessageTemplate.internal_name,
                filter: this.filter_values.selectedFilters.internal_name
              }
            },

          )
        ).data;
      }

      console.log("response.status", response.status);
      if (response.status == 'success') {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_messagesHaveBeenDeliveredSuccessfully'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

      } else {
        this.$swal({
          icon: 'warning',
          title: this.lang('t_warning'),
          text: this.lang('t_thereWasAProblemDeliveringYourMessages'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }
    },
    GetSmsData: async function () {
      // console.log("filter_values.selectedFilters", this.filter_values.selectedFilters);
      //  console.log("filter_values.selectedMessageTemplate", this.filter_values.selectedMessageTemplate);
      //  console.log("asd",  `${this.filter_values.selectedProject.internal_name}_customers`);
      this.is_progress = true;
      var filter = this.mongoize_campaign_filters(this.filter_values.selectedFilters.filters);
      var response = (
        await this.$http_in.post(
          `spv/v1/SmsData`,
          {
            filter: filter,
            collection: `${this.filter_values.selectedProject.internal_name}_customers`
          }
        )
      ).data;

      this.preview_message = this.filter_values.selectedMessageTemplate.message;
      console.log("preview_message", this.preview_message);
      if (response.length > 0) {
        for (const key of Object.keys(response[0])) {
          this.filter_values.selectedMessageTemplate.message = this.filter_values.selectedMessageTemplate.message.replace(
            '{{' + key + '}}',
            response[0][key]
          );
        }
        this.show_textarea = true;
      }

      this.filtered_data = response;
      console.log("filtered_data", this.filtered_data);
      this.is_progress = false;

      this.preview_click = true;
    },
    get_smssettings: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsSettings`)
      ).data;

      this.operators = response;
    },
    get_smsfilters: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsFilter`)
      ).data;
      //this.columns = response;
      this.filters = response;
    },
    get_smstemplate: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/SmsTemplate`)
      ).data;
      //this.columns = response;
      this.templates = response;
    },

    get_projects: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/Project`)
      ).data;
      this.projects = response;
    },
  },

}
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
</style>

<template>
  <div>
    <sms-sender></sms-sender>
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
import SmsSender from "./SmsSender/Sender.vue";
import SmsFilter from "./SmsFilter/list/List.vue";
import SmsTemplate from "./SmsTemplate/list/List.vue";
import Stt from "./STT/list/List.vue";


export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      selectedButton: "sender",
      optionsButton: [
        { text: globalThis._lang("t_smsSending"), value: "sender" },
        { text: globalThis._lang("t_smsTemplate"), value: "template" },
        { text: globalThis._lang("t_smsFilter"), value: "filter" },
        { text: globalThis._lang("t_settings"), value: "settings" },
        { text: 'STT', value: "stt" },
      ],
    };
  },
  components: {
    Stt,
    SmsFilter,
    SmsTemplate,
    SmsSender,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
};
</script>

<style>
</style>

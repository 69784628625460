<template>
  <div>
    <b-row>
      <b-col>
        <b-dropdown
          id="dropdown-1"
          class="float-right"
          :text="lang('t_sendSMS')"
          variant="flat-primary"
        >
          <template #button-content>
            {{ lang("t_sendSMS") }}
            <i class="bi bi-send ml-1"></i>
          </template>
          <b-dropdown-item
            @click="
              () => {
                sender_info.type = item.key;
                modal_send = true;
              }
            "
            v-for="item in types"
            :key="item.key"
          >
            {{ item.value }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <sms-template></sms-template>
    <sms-filter></sms-filter>

    <b-modal
      hide-footer
      :title="types.find((e) => e.key == sender_info.type).value"
      v-model="modal_send"
      centered
      size="lg"
    >
      <special-sms
        v-if="sender_info.type == 'special_sms'"
        ref="specialSmsComponent"
        key="specialSms"
      ></special-sms>
      <system-sms
        v-if="sender_info.type == 'system_sms'"
        ref="systemSmsComponent"
      ></system-sms>

      <external-sms
        v-if="sender_info.type == 'external_sms'"
        ref="externalSmsComponent"
      ></external-sms>
    </b-modal>

    <b-card-text v-if="false">
      <b-row>
        <b-col cols="12">
          <div class="mx-auto" max-width="100%">
            <b-card>
              <b-row>
                <b-col class="pb-0" cols="12">
                  <b-row>
                    <!-- Gönderim Seçenekleri -->
                    <b-col cols="12" class="mb-50">
                      <b-form-group>
                        <v-select
                          v-model="sender_info.type"
                          :options="types"
                          label="value"
                          :reduce="(item) => item.key"
                          :placeholder="lang('t_sendType')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { FormWizard, TabContent } from 'vue-form-wizard'

import SystemSms from "./TransferSend/SystemSms.vue";
import ExternalSms from "./TransferSend/ExternalSms.vue";
import SpecialSms from "./TransferSend/SpecialSms.vue";
import vSelect from "vue-select";
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import SmsTemplate from '../SmsTemplate/list/List.vue'
import SmsFilter from '../SmsFilter/list/List.vue'
export default {
  components: {
    SmsTemplate,
    SmsFilter,
    BDropdown,
    BDropdownItem,
    FormWizard, TabContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    BCardText,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    SystemSms,
    SpecialSms,
    ExternalSms,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      sender_info: {
        type: "special_sms",
      },
      modal_send: false,
      types: [
        {
          key: "system_sms",
          value: globalThis._lang("t_sendFromVoyceCRM"),
        },
        {
          key: "external_sms",
          value: globalThis._lang("t_sendFromExternalCRM"),
        },
        {
          key: "special_sms",
          value: globalThis._lang("t_sendFromCustomSms"),
        },
      ],
    };
  },
  methods: {

    handleComplete() {
      if (this.sender_info.type == 'system_sms') {
        this.$refs.systemSmsComponent.send_sms();
      } else if (this.sender_info.type == 'external_sms') {
        this.$refs.externalSmsComponent.send_excel_sms();
      } else if (this.sender_info.type == 'special_sms') {
        this.$refs.specialSmsComponent.send_special_sms();
      }
    }
  }
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#dropdown-1 .btn.dropdown-toggle::after {
  display: none; /* Varsayılan ok işaretini gizleyin */
}
</style>
